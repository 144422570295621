import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormFeedback,
  FormGroup,
  Input,
  CardTitle,
  Alert,
  Table,
} from "reactstrap";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import OPSSWAddNewCommentAttachmentService from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSSWAddNewCommentAttachmentService";
import Assigned from "../../../../../../../designUtils/Storeoverview/status/Assigned.svg";
import Closed from "../../../../../../../designUtils/Storeoverview/status/Closed.svg";
import NeedInfo from "../../../../../../../designUtils/Storeoverview/status/NeedInfo.svg";
import Open from "../../../../../../../designUtils/Storeoverview/status/Open.svg";
import Resolved from "../../../../../../../designUtils/Storeoverview/status/Resolved.svg";
import Updated from "../../../../../../../designUtils/Storeoverview/status/Updated.svg";
import InProgress from "../../../../../../../designUtils/Storeoverview/status/InProgress.svg";
import Pending from "../../../../../../../designUtils/Storeoverview/status/Pending.svg";
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from "../../../../../../../Utils/Constants";
import OPSSWGetCommentAttachmentService from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSSWGetCommentAttachmentService";
import OPSGetAllProductsAndMembers from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSGetAllProductsAndMembers";
function OpsDashSWViewTicket(props) {
  const [ticketHistory, setTicketHistory] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [text, setText] = useState("");
  const [ticketHistoryText, setTicketHistoryText] = useState("");
  const [ticketHistoryUpdatedBy, setTicketHistoryUpdatedBy] = useState("");
  const [isTicketHistoryTextOpen, setIsTicketHistoryTextOpen] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState({
    ticketStatus: "",
  });
  const [searchText, setSearchText] = useState("");
  const [state, setState] = useState({
    ticketTitle: "",
    ticketRequestType: "",
    ticketDate: "",
    ticketStatus: "",
    issueDescription: "",
    priority: "",
    assignedTo: "",
    assignedToID: "",
    ticketSubRequestType: "",
    Asin: "",
    Sku: "",
    problemAreaId: "",
    problemAreaTitle: "",
    problemAreaPathwayId: "",
    problemAreaPathwayTitle: "",
    isInternalTicket: "",
  });
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };

  const setTicketInfo = () => {
    if (props.ticketList && props.ticketId) {
      var requestMainType = "";
      props.ticketList.map((ticket) => {
        {
          if (ticket.ticketId === props.ticketId) {
            props.mainRequestTypes.map((Type) => {
              {
                if (Type.id === ticket.mainRequestTypeId) {
                  let asinArray = [];
                  let problemAreaPathwayTitle = "";
                  if (props.problemAreaPathwayList) {
                    props.problemAreaPathwayList.map((pap) => {
                      if (props.problemAreaPathwayId == pap.id) {
                        problemAreaPathwayTitle = pap.problemAreaPathway;
                      }
                    });
                  }
                  setState({
                    ticketTitle: ticket.ticketTitle,
                    ticketDate: ticket.ticketCreatedAt,
                    ticketStatus: ticket.ticketStatus,
                    issueDescription: ticket.issueDescription,
                    priority: ticket.priority,
                    assignedTo: ticket.assignedTo,
                    assignedToID: ticket.assignedToID,
                    ticketRequestType: Type.requestType,
                    ticketSubRequestType: ticket.requestSubTypes,
                    //Asin: asinArray,//ticket.Asin,
                    Sku: ticket.Sku,
                    problemAreaPathwayTitle: ticket.problemAreaPathway,
                    isInternalTicket: ticket.isInternalTicket,
                  });
                }
              }
            });
          }
        }
      });
    }
  };
  const handleFileChange = (e) => {
    const newFiles = [...selectedFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setSelectedFiles(newFiles);
  };
  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  const handleEditor = (value) => {
    setText(value)
  }
  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setStatus({ ...status, [name]: value });
  };
  const handleAssignedTo = (e) => {
    const { name, value } = e.target;
    setAssignedTo(value);
    console.log('first')
  };
  const handlePriorityChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setPriority(value);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    const data = {
      ticketId: props.ticketId,
    };
    OPSSWGetCommentAttachmentService(props.token, data).then((response) => {
      if (response) {
        console.log(response);
        if (ticketHistory) {
          setTicketHistory(response.ticketHistory);
        }
        if (response.productList) {
          setProductList(response.productList);
        }
      }
    });
    // OPSGetAllProductsAndMembers(props.token, data).then((response) => {
    //   if (response) {
    //     console.log(response);
    //     if (response.productList)
    //     {
    //       setProductList(response.productList)
    //     }
    //   }
    // })
  }, []);
  useEffect(() => {
    setTicketInfo();
  }, []);




  const filteredTicketHistory = ticketHistory.filter((row) => {
    const historyComment = row.ticketHistoryComment.toLowerCase();
    return historyComment.includes(searchText.toLowerCase());
  });

  const handleUpload = async (flag) => {
    console.log(text)
    const formData = new FormData();
    if (state.isInternalTicket && flag == 3) {
      formData.append("ticketStatus", 'Closed');
      setLoading1(true)
    }
    else if (props.accessLevel == 0) {
      formData.append("ticketStatus", state.ticketStatus);
      setLoading(true)
    }
    // else if (props.accessLevel != 0 && flag == 1) {
    //   formData.append("ticketStatus", 1);
    //   setLoading(true)
    // } 
    else {
      setLoading(true)
      formData.append("ticketStatus", 0);
    }
    formData.append("ticketHistoryComment", text);
    formData.append("priority", priority ? priority : state.priority);
    formData.append("assignedTo", assignedTo ? assignedTo : state.assignedToID);
    formData.append("ticketId", props.ticketId);
    formData.append("storeName", props.storeNameForViewTicket);
    formData.append("integerId", props.storeIdForViewTicket);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("ticketAttachmentFile", selectedFiles[i]);
    }
    // return true
    OPSSWAddNewCommentAttachmentService(props.token, formData).then(
      (response) => {
        if (response) {
          console.log(response);
          setText("");
          setSelectedFiles(null);
          props.handleCreateAndViewTicketClose(response);
          setLoading(false);
          setLoading1(false);
          setLoading2(false);

        }
      }
    );
  };
  const show = (path) => {
    const separatedPath = path.split("/");
    console.log("first");
    console.log(separatedPath[1]);
    const ext = separatedPath[1].split(".")[1];
    console.log(ext);
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
      setFilePath(path);
      setIsAttachmentOpen(true);
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      setFilePath(path);
      setIsAttachmentOpen(true);
    }
  };
  const downloadFile = async (file) => {
    console.log(file)
    if (true) {
      try {
        let fileName = file.ticketAttachment.split('/')[1]
        fileName = fileName.split('.')[0]
        const response = await fetch("https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" + file.ticketAttachment);
        if (!response.ok) throw new Error(`Network response was not ok for ${file.ticketAttachmentId}`);

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName || 'downloaded_file';
        // downloadLink.download = 'downloaded_file';

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Failed to download files:', error);
      }
    }
  }
  const viewSingleHistory = (text, updatedBy) => {
    setTicketHistoryText(text);
    setTicketHistoryUpdatedBy(updatedBy);
    setIsTicketHistoryTextOpen(true);
  };
  const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
  const columns = [
    // {
    //   Header: "Priority",
    //   accessor: "ticketPriority",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Communication",
    //   accessor: "Path",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },setText
    {
      Header: "Date",
      accessor: "ticketHistoryCreatedAtOriginal",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.original.ticketHistoryCreatedAt}</p>
        </div>
      ),
    },
    {
      Header: "Comments",
      accessor: "ticketHistoryComment",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p
            className="m-0 comment-paragraph"
            style={{ color: "#3D85C6", cursor: "pointer" }}
            onClick={() =>
              viewSingleHistory(
                cellInfo.value,
                cellInfo.original.ticketHistoryCreateBy
              )
            }
          >
            {stripHtmlTags(cellInfo.value)}
          </p>
        </div>
      ),
    },
    {
      Header: "Updated by",
      accessor: "ticketHistoryCreateBy",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    // {
    //   Header: "Assigned",
    //   accessor: "assignedTouserName",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
    {
      Header: "Status",
      accessor: "ticketHistoryStatus",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span
            className={
              cellInfo.value === "Closed"
                ? "btn btn-sm text-primary "
                : cellInfo.value === "New"
                  ? "btn btn-sm text-danger"
                  : cellInfo.value === "Resolved"
                    ? "btn btn-sm text-success"
                    : cellInfo.value === "Need info"
                      ? "btn btn-sm text-info "
                      : cellInfo.value === "Informed"
                        ? "btn btn-sm text-secondary "
                        : cellInfo.value === "Ticket updated"
                          ? "btn btn-sm text-warning"
                          : cellInfo.value === "Assigned"
                            ? "btn btn-sm text-warning"
                            : "btn btn-sm"
            }
          >
            {cellInfo.value}
          </span>
        </div>
      ),
    },
  ];
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };

  return (
    <Container fluid className="">
      <Card className="p-3 bgColor">
        <Row
          className="m-0 justify-content-center"
          style={{ fontSize: "19px" }}
        >
          <Col md={12}>
            <Row>
              <Col md={3}>
                <div className="d-flex align-items-center">
                  <p>
                    {/* <b>Subject : </b> {state.ticketTitle} */}
                    <b>Ticket Id : </b> {props.ticketId}
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <Label>
                  {state.isInternalTicket ? (
                    <span className="ml-3">
                      <FontAwesomeIcon
                        style={{ color: "#05837c" }}
                        icon={faClipboardCheck}
                      />
                      &nbsp;<b>Internal Ticket</b>
                    </span>
                  ) : (
                    ""
                  )}
                </Label>
              </Col>
              <Col md={5}>
                <Label for="Status">
                  <Col className="img-class-button pb-0 pt-0 d-flex justify-content-end">
                    <p
                      className={
                        state.ticketStatus === "New"
                          ? "Open-button mb-0 pt-0"
                          : state.ticketStatus === "Closed"
                            ? "closed-button  pb-0 pt-0"
                            : state.ticketStatus === "Resolved"
                              ? "Resolved-button pb-0 pt-0"
                              : state.ticketStatus === "Need info"
                                ? "Needinfo-button pb-0 pt-0"
                                : state.ticketStatus === "Informed"
                                  ? "Inprogress-button pb-0 pt-0"
                                  : state.ticketStatus === "Ticket Updated"
                                    ? "Ticketupdat-button pb-0 pt-0"
                                    : state.ticketStatus === "Assigned"
                                      ? "Assigned-button pb-0 pt-0"
                                      : state.ticketStatus === "Pending"
                                        ? "Pending-button pb-0 pt-0"
                                        : ""
                      }
                    >
                      {state.ticketStatus === "Closed" ? (
                        <img className="img-class" src={Closed} />
                      ) : state.ticketStatus === "New" ? (
                        <img className="img-class" src={Open} />
                      ) : state.ticketStatus === "Resolved" ? (
                        <img className="img-class" src={Resolved} />
                      ) : state.ticketStatus === "Informed" ? (
                        <img className="img-class" src={InProgress} />
                      ) : state.ticketStatus === "Ticket Updated" ? (
                        <img className="img-class" src={Updated} />
                      ) : state.ticketStatus === "Assigned" ? (
                        <img className="img-class" src={Assigned} />
                      ) : state.ticketStatus === "Pending" ? (
                        <img className="img-class" src={Pending} />
                      ) : state.ticketStatus === "Need info" ? (
                        <img className="img-class" src={NeedInfo} />
                      ) : (
                        ""
                      )}
                      <span style={{ color: "white" }} className="status-text">
                        {state.ticketStatus}
                      </span>
                    </p>
                  </Col>
                </Label>
              </Col>
              {/* <Col md={6}>
              <Label for="Request Type">
                <b>Request Type : </b>
              </Label>
              <p> {state.ticketRequestType}</p>
            </Col> */}
              <Col md={6} className="mt-2">
                <Label for="Problem Area Pathway Type">
                  <b>Problem Area : </b>
                </Label>
                <p> {state.problemAreaPathwayTitle}</p>
              </Col>
              <Col md={6} className="mt-2">
                <Label for="Request Sub Type">
                  <b>Problem Type : </b>
                </Label>
                <p> {state.ticketSubRequestType}</p>
              </Col>
              {/* <Col md={6}>
            <Label for="Request Sub Type">
              <b>ASIN : </b>
            </Label>
            <p> {state.Asin.split(',').join(', ') }</p>
          </Col>
          <Col md={6}>
            <Label for="Request Sub Type">
              <b>SKU : </b>
            </Label>
            <p> {state.Sku}</p>
          </Col> */}
              <Col md={12}>
                <Label htmlFor="subRequestType">
                  <b>Product(s) :</b>
                </Label>
                <div
                  className={
                    productList
                      ? productList.length > 0
                        ? "scrollbar "
                        : " "
                      : ""
                  }
                >
                  {productList ? (
                    productList.length > 0 ? (
                      productList.map((product) => (
                        <>
                          <Row className="border row-background-color justify-content-center p-0 m-0">
                            <Col md="2" className="p-2">
                              <div>
                                <img
                                  className="ml-0"
                                  width={"100%"}
                                  src={product.itemImage}
                                />
                              </div>
                            </Col>
                            <Col md="10" className="p-2">
                              <div>
                                <h6>
                                  <b>
                                    <a
                                      href={product.link}
                                      className="text-decoration-none "
                                      target="blank"
                                    >
                                      {product.productTitle}{" "}
                                    </a>
                                  </b>
                                </h6>
                                <div className="">
                                  <Row>
                                    <Col md={5}>
                                      <span>
                                        <b>ASIN : </b> {product.asin}
                                      </span>
                                    </Col>
                                    <Col md={7}>
                                      {/* <div>
                                                      <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                                    </div> */}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ))
                    ) : (
                      <p className="text-center"> No product selected .</p>
                    )
                  ) : (
                    <p className="text-center"> No product selected .</p>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <Label for="Created Date">
                  <b>Issue Description : </b>
                </Label>
                {/* <p>{stripHtmlTags(state.issueDescription)}</p> */}
                <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: state.issueDescription }} />
              </Col>

              <Col md={6}>
                <Label for="assignedTo">
                  <b>Assigned to : </b>
                </Label>
                <p>
                  {props.accessLevel === 0 &&
                    state.ticketStatus !== "Closed" ? (
                    <select
                      id="assignedTo"
                      name="assignedTo"
                      onChange={handleAssignedTo}
                      value={assignedTo ? assignedTo : state.assignedToID}
                      className="form-control"
                    >
                      <option value="">--Select Member--</option>
                      {props.teamMembers
                        ? props.teamMembers.map((member) => (
                          // (state.problemAreaPathwayTitle == member.specialization)
                          //           ?
                          <option
                            value={member.employeeId}
                            title={`${member.note}`}
                          >
                            {member.employeeName}
                          </option>
                            // :''
                           /*:''*/))
                        : ""}
                    </select>
                  ) : (
                    state.assignedTo
                  )}
                </p>
              </Col>
              <Col md={6}>
                <Label for="priority">
                  <b>Priority : </b>
                </Label>
                <p>
                  {" "}
                  {props.accessLevel === 0 &&
                    state.ticketStatus !== "Closed" ? (
                    <select
                      id="priority"
                      name="priority"
                      onChange={handlePriorityChange}
                      value={priority ? priority : state.priority}
                      className="form-control"
                      disabled={state.priority === "Internal Ticket"}
                    >
                      <option value="">--Select Priority--</option>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Normal">Normal</option>
                      <option value="Low">Low</option>
                      <option value="Onboarding Task">Onboarding Task</option>
                      <option value="Internal Ticket">Internal Tiket</option>
                    </select>
                  ) : (
                    state.priority
                  )}
                </p>
              </Col>
              <Col md={12} className='mt-2'>
                {state.ticketStatus !== "Closed" && (
                  <>
                    <Label for="Comment">
                      <b>Comments For SW Team : </b>
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </Label>
                    {/* <Input
                      id="ticketHistoryComment"
                      value={text}
                      type="textarea"
                      name="ticketHistoryComment"
                      onChange={handleTextChange}
                    /> */}
                    {/* <ReactQuill
                     className="quil-style"
                     type='text'
                      modules={module}
                      theme="snow" 
                      value={text}
                      onChange={handleEditor} /> */}
                    <ReactQuill
                      theme={'snow'}
                      modules={Editor.modules}
                      formats={Editor.formats}
                      value={text}
                      onChange={handleEditor}
                      bounds={'.quill-prod'}
                      placeholder="Please describe your problem"
                      />
                    {/* <button onClick={()=>handleSave()}> save</button> */}
                    {/* <EditorControll/> */}
                  </>
                )}
              </Col>
              <Col md={6}>
                {state.ticketStatus !== "Closed" && (
                  <>
                    <Label for="Attachment">
                      <b>Helpful screenshots or images : </b>
                    </Label>
                    {/*  <Input
                      id="ticketAttachment"
                      name="ticketAttachment"
                      className="mt-3"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    /> */}
                    <label
                      htmlFor="fileInput"
                      className="custom-file-input-label text-center"
                    >
                      <i className="fas fa-upload"></i>
                      <br /> Choose File
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      name="ticketAttachment"
                      className="custom-file-input"
                      onChange={handleFileChange}
                    />
                    <span style={{ fontSize: "13px" }}>Attached file List</span>
                  </>
                )}

                <ul style={{ color: "green", fontSize: "13px" }}>
                  {selectedFiles
                    ? selectedFiles.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <button
                          className="btn"
                          onClick={() => handleRemoveFile(file)}
                        >
                          <FontAwesomeIcon
                            color="red"
                            size="15px"
                            icon={faWindowClose}
                          />
                        </button>
                      </li>
                    ))
                    : ""}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <span>
                  {state.ticketStatus !== "Closed" && (
                    <Button
                      className="btn_class float-right ml-2"
                      size="lg"
                      onClick={() => handleUpload(2)}
                      disabled={(loading ? true : false)}
                    >
                      {loading ? 'Saving...' : 'Save'}
                    </Button>
                  )}
                  {state.isInternalTicket ? (
                    <Button
                      className="btn_class float-right ml-2"
                      size="lg"
                      onClick={() => handleUpload(3)}
                      disabled={(loading1 ? true : false)}
                    >
                      {loading1 ? 'Saving...' : 'Mark Resolved'}
                    </Button>
                  ) : (
                    ""
                  )}

                  {/* {state.ticketStatus === "Resolved" &&
                  props.accessLevel !== 0 ? (
                    <Button
                      className="btn_class float-right mr-2"
                      size="lg"
                      onClick={() => handleUpload(1)}
                      disabled={!text ? true :( loading ? true : false) }
                    >
                      {loading ? 'Closing...' : 'Close'}
                    </Button>
                  ) : (
                    ""
                  )} */}
                </span>
                <p className="text-center">
                  {state.ticketStatus === "Closed" && (
                    <Alert>Note: This ticket is closed.</Alert>
                  )}
                  {/* {state.ticketStatus !== 'Closed' && (
                <Button
                  className="btn_class"
                  size="lg"
                  onClick={handleUpload}
                >
                  Save
                </Button>
              )} */}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row className="m-0">
        <Col md="12" className="mbg-3 font-size-lg p-0">
          <Card className="main-card  mt-4 p-2 card-of-problemarea ">
            <CardBody className="pt-0">
              <CardTitle
                className=""
                style={{ fontSize: "20px", color: "#6c757d" }}
              >
                SW Execution History
              </CardTitle>
              <div>
                <input
                  type="text"
                  className="form-control w-25 mb-3"
                  placeholder="Search Ticket History"
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </div>
              <ReactTable
                data={filteredTicketHistory}
                columns={columns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={10}
                noDataText={"No Data Found."}
                SubComponent={(row) => (
                  <div className="p-1">
                    {console.log(row.original.ticketAttachment)}
                    {row.original.ticketAttachment.length > 0 ? (
                      <ReactTable
                        data={row.original.ticketAttachment}
                        columns={[
                          {
                            Header: "Name",
                            accessor: "ticketAttachment",
                            Cell: (cellInfo) => (
                              <div className="d-flex justify-content-center w-100">
                                {cellInfo.value.split("/")[1]}
                              </div>
                            ),
                          },
                          {
                            Header: "Action",
                            accessor: "ticketAttachment",
                            Cell: (cellInfo) => (
                              <div className="d-flex justify-content-center w-100">
                                {
                                  fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                                    <FontAwesomeIcon
                                      className="ml-3"
                                      style={{ cursor: 'pointer' }}
                                      icon={faDownload}
                                      onClick={() => downloadFile(cellInfo.original)}
                                    />
                                  ) : (
                                    <>
                                      <FontAwesomeIcon
                                        className="ml-3"
                                        style={{ cursor: 'pointer' }}
                                        icon={faEye}
                                        onClick={() => show(cellInfo.value)}
                                      />
                                      <FontAwesomeIcon
                                        className="ml-3"
                                        style={{ cursor: 'pointer' }}
                                        icon={faDownload}
                                        onClick={() => downloadFile(cellInfo.original)}
                                      />
                                    </>
                                  )
                                }

                              </div>
                            ),
                          },
                        ]}
                        getTheadProps={() => {
                          return {
                            style: {
                              overflowY: "hidden",
                              background: "#B1FA63",
                              color: "#243837",
                            },
                          };
                        }}
                        className="-striped -highlight"
                        defaultPageSize={row.original.ticketAttachment.length}
                        noDataText="No Attachments available"
                      />
                    ) : (
                      <p className="text center">No Attachments.</p>
                    )}
                  </div>
                )}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomModal
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'ticketAttachment': filePath, 'ticketAttachmentId': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsAttachmentOpen(!isAttachmentOpen)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                "https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" +
                filePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      <CustomModal
        header={"Comment " + "Updated by - " + ticketHistoryUpdatedBy}
        isOpen={isTicketHistoryTextOpen}
        toggle={() => setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label><p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: ticketHistoryText }} /></Label>
      </CustomModal>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(OpsDashSWViewTicket);
