import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Input, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import BackButton from "../../../../../../../designUtils/Logo/BackButton.svg";
import GetWorkflowTaskService from "../../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowTaskService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCloudUploadAlt,
    faWindowClose,
    faCheck
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import SaveTaskDetailsService from "../../../../../../../Service/OPSServices/WorkflowsService/SaveTaskDetailsService";
import GetProgressBarDataService from "../../../../../../../Service/OPSServices/WorkflowsService/GetProgressBarDataService";
import ReactTable from "react-table";
import PriveTab from "../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/Prev_Ques_Arrow.svg";
import TaskDetailsComponents from "./TaskDetailsComponents";
import GetAllWorkflowStatusService from "../../../../../../../Service/OPSServices/WorkflowsService/GetAllWorkflowStatusService";
import TaskListDetailsPriviewModeComponents from "./TaskListDetailsPriviewModeComponents";

function WorkflowPhasesPage(props) {
    const [currentPhaseId, setCurrentPhaseId] = useState(1);
    const [taskList, setTaskList] = useState();
    const [allTasks, setAllTasks] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [status, setStatus] = useState("");
    const [workflowPhaseCount, setWorkflowPhaseCount] = useState([]);
    const [totalWorkflowPhaseCount, setTotalWorkflowPhaseCount] = useState();
    const [flag, setFlag] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [isOpneTaskListDetails, setIsOpneTaskListDetails] = useState(false);
    const [taskDetails, setTaskDetails] = useState();
    const [statusList, setStatusList] = useState();
    const [isPriviewModeOn, setIsPriviewModeOn] = useState(false);
    const [teamMembers, setTeamMembers] = useState()
    const [member, setMember] = useState()
    const [satusLoading, setSatusLoading] = useState(false)
    const [assignedLoading, setAssingedLoading] = useState(false)
    const [loadding, setLodding] = useState(false);

    useEffect(() => {
        setLodding(true)
        let data = {
            integerId: props.selectedStoreData.integerId,
            workflowMasterId: props.selectedWorkflowMasterId
        }
        GetWorkflowTaskService(props.token, data).then((res) => {
            if (res) {

                console.log(res);
                console.log("mahesh");
                setTeamMembers(res.teamMembers)
                setTaskList(res.tasklist);
                setAllTasks(res.tasklist)
                setLodding(false)
            }
        });
        GetAllWorkflowStatusService(props.token).then((responce) => {
            if (responce) {
                console.log(responce);
                setStatusList(responce.statusList);
            }
        });
        GetProgressBarDataService(props.token, data).then((response) => {
            if (response) {
                console.log(response)
                setWorkflowPhaseCount(response.workflowPhasesCount);
                setTotalWorkflowPhaseCount(response.totalWorkflowPhasesCount);
            }
        });

    }, []);

    const handleSearchInputChange = (e) => {
        setTaskList(allTasks);
        setSearchInput(e.target.value);
        filteredTasktHistory(e.target.value);
    };
    const filteredTasktHistory = (input) => {
        const stringQuery = input.toString();
        if (isPriviewModeOn) {
            if (input !== "") {
                const filtered = allTasks.filter((task) => task.taskText.toLowerCase().includes(stringQuery.toLowerCase()));
                setTaskList(filtered);
                console.log(filtered.length)
            }

        }
        else {
            if (input !== "") {
                const filtered = allTasks.filter((task) => task.taskText.toLowerCase().includes(stringQuery.toLowerCase()));
                if (filtered.length > 0) {
                    setCurrentPhaseId(filtered[0].WorkflowPhases);
                    setTaskList(filtered);
                }
                else {
                    setCurrentPhaseId(0)
                    setTaskList(allTasks);
                }
            }
            else {
                setCurrentPhaseId(1)
                setTaskList(allTasks);
                return true
            }
        }
    };

    const handleOpenTaskDetailsPage = (task) => {
        setIsOpneTaskListDetails(true);
        setIsPriviewModeOn(false);
        setTaskDetails(task);
    };
    const handleBackTaskList = () => {
        setIsOpneTaskListDetails(false);
        setIsPriviewModeOn(false);
    };
    const handleStatusChange = (e, task) => {
        setStatus(e.target.value)
        saveTaskDetails('status', e.target.value, task)
    };
    const handleAssignedToChange = (e, task) => {
        setMember(e.target.value)
        saveTaskDetails('assignedTo', e.target.value, task)
    }
    const handlePriviewMode = () => {
        setIsPriviewModeOn(!isPriviewModeOn);
        setIsOpneTaskListDetails(false);
    };
    const saveTaskDetails = (type, value, taskDetails) => {
        if (!taskDetails) {
            return true;
        }

        let data;
        setLoading(true)
        // setSatusLoading(taskDetails.id)
        if (type == 'assignedTo') {
            data = {
                taskId: taskDetails.id,
                taskText: 'assignedTo',
                workflowMasterId: props.selectedWorkflowMasterId,
                assignedTo: value ? value : taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0,
                workflowPhaseId: currentPhaseId,
                integerId: props.selectedStoreData.integerId,
                status: taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0,
                attachmentFile: []
            }
        }
        else if (type == 'status') {
            data = {
                taskId: taskDetails.id,
                taskText: 'status',
                workflowMasterId: props.selectedWorkflowMasterId,
                assignedTo: taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0,
                workflowPhaseId: currentPhaseId,
                integerId: props.selectedStoreData.integerId,
                status: value ? value : taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0,
                attachmentFile: []
            }
        }
        else {
            return true
        }

        SaveTaskDetailsService(props.token, data).then((res) => {
            if (res.status) {
                console.log(res)
                setTaskDetails()
                setLoading(false)
                setStatus('')
                setFlag(0)
                setMember()
                setComment('')
                setSelectedFiles([])
                setTaskList(res.tasklist.tasklist)
                setWorkflowPhaseCount(res.progressBarData.workflowPhasesCount);
                setTotalWorkflowPhaseCount(res.progressBarData.totalWorkflowPhasesCount);
                setSatusLoading()
                setAssingedLoading()

            }
        })



    }
    return (
        <>
            <Card className="pr-3 pl-3 pt-1 pb-1 mb-2  card-of-problemarea">
                <Row>
                    <Col md={12}>
                        <div className="d-flex">
                            <button
                                onClick={() => {
                                    props.handleBackButtonToWorkflow();
                                }}
                                className="hover-btn-link back-btn"
                            >
                                <img src={BackButton} alt="not found" />
                            </button>
                            <h5 className="mb-0 mt-2">
                                <b>{props.selectedStoreData.storeName}</b>
                            </h5>
                            {props.workflowMainCategory.map((workMC) =>
                                workMC.id == props.selectedWorkflowMasterId ? (
                                    <h5 className="mb-0 mt-2">

                                        &nbsp; : &nbsp;<b>{workMC.name} </b>
                                    </h5>
                                ) : (
                                    ""
                                )
                            )}
                            <div className="mt-1 ml-5" >
                                <label className="toggle-switch ml-3">
                                    <input type="checkbox" onChange={() => handlePriviewMode()} />
                                    <span className="dot"></span>
                                </label>
                                <span className="custom-label ml-1 mr-2  mt-1"> View All Tasks</span>
                            </div>

                            <Input
                                type="text"
                                value={searchInput}
                                disabled={loadding ? true : false}
                                onChange={(e) => handleSearchInputChange(e)}
                                className="form-control w-25 ml-auto"
                                placeholder="Search Tasks "
                            />
                        </div>
                    </Col>

                    <Col md={12} className="p-2">
                        {!isPriviewModeOn ?
                            <div className="d-flex">
                                <>

                                    {props.workflowPhases
                                        ? props.workflowPhases.map((phase) => (
                                            <>
                                                {props.selectedWorkflowMasterId ==
                                                    phase.WorkflowsMaster ? (
                                                    <div
                                                        onClick={() => {
                                                            if (!loadding) {
                                                                setCurrentPhaseId(phase.id);
                                                                setIsOpneTaskListDetails(false);
                                                            }
                                                        }}
                                                        style={{
                                                            cursor: loadding ? "not-allowed" : "pointer",
                                                            opacity: loadding ? 0.5 : 1
                                                        }}
                                                        className={
                                                            phase.id == currentPhaseId
                                                                ? "selected-tab-workflows m-2"
                                                                : "pt-3 pb-3 m-2"
                                                        }
                                                    >
                                                        <p className="text-center p-0 mb-0" style={{ fontSize: '17px' }} key={phase.id}>
                                                            <b>{phase.name}</b>
                                                        </p>
                                                        {workflowPhaseCount
                                                            && workflowPhaseCount.map((count) => (
                                                                <p className="text-center p-0 mb-0">
                                                                    {phase.id === count.WorkflowPhases_id
                                                                        ? count.attemptedTaskCount +
                                                                        " of " +
                                                                        count.taskCount +
                                                                        " Completed"
                                                                        : ""}
                                                                </p>
                                                            ))
                                                        }

                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ))
                                        : ""}
                                </>
                            </div> : ''}
                    </Col>
                </Row>
            </Card>
            {!isPriviewModeOn ? (
                <>
                    {!isOpneTaskListDetails ? (
                        <Card className="card-min-height-workflows  card-of-problemarea">
                            <Col md={12} className="m-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Task</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Assigned To</th>
                                            <th scope="col">Last Update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            taskList &&
                                            taskList.map((task) => (
                                                <>
                                                    {console.log(task)}
                                                    {currentPhaseId === task.WorkflowPhases && (
                                                        <tr key={task.taskId}>
                                                            <td

                                                                style={{ cursor: "pointer", width: "30%" }}
                                                                onClick={() =>
                                                                    handleOpenTaskDetailsPage(task)
                                                                }
                                                            >
                                                                <a href="#">
                                                                    {task.taskText}</a>
                                                            </td>
                                                            <td>{satusLoading === task.id ? <p className="mb-0" style={{ color: '#81e94b' }}>Updating..</p>
                                                                : <>
                                                                    {task.answerObject[0] && task.answerObject[0].status == 6 ?
                                                                        <><span className="signedoff-dropdown "> Signed Off &nbsp; &nbsp;<FontAwesomeIcon style={{ color: '#fff' }} icon={faCheck} />  </span> <span className="ml-2">{task.answerObject[0].updatedByName && task.answerObject[0].updatedByName.split(' ')[0] + ' ' + task.answerObject[0].updatedByName.split(' ')[1][0] + '.'}</span></> :
                                                                        <select
                                                                            className={`w-75 ${task.answerObject[0] ?
                                                                                task.answerObject[0].status == 3 ? 'inprogress-dropdown form-control' :
                                                                                    task.answerObject[0].status == 5 ? 'completed-dropdown form-control' :
                                                                                        task.answerObject[0].status == 2 ? 'notstarted-dropdown form-control' :
                                                                                            task.answerObject[0].status == 1 ? 'notapplicable-dropdown form-control' :
                                                                                                task.answerObject[0].status == 4 ? 'postpond-dropdown form-control' :
                                                                                                    'form-control' : 'form-control'}`}
                                                                            name="status"
                                                                            id="status"
                                                                            onChange={(e) => { handleStatusChange(e, task); setSatusLoading(task.id) }}                                                                   >
                                                                            <option style={{ background: '#fff' }} value="">-- Select status --</option>
                                                                            {statusList &&
                                                                                statusList.map((status) => (
                                                                                    <option style={{ background: '#fff' }} key={status.id} value={status.id} selected={status.id === (task.answerObject[0] && task.answerObject[0].status) ? true : false}>
                                                                                        {status.status}
                                                                                    </option>
                                                                                ))}
                                                                        </select>
                                                                    }
                                                                </>
                                                            }
                                                            </td>
                                                            <td>{task.answerObject[0] && task.answerObject[0].status == 6 ? <h6 className="ml-2"> {task.answerObject[0].executorName}</h6> :
                                                                <>
                                                                    {assignedLoading === task.id ? <p className="mb-0" style={{ color: '#81e94b' }}>Updating..</p> :
                                                                        <select
                                                                            className="form-control"
                                                                            name="assignedto"
                                                                            id="assignedto"
                                                                            onChange={(e) => { handleAssignedToChange(e, task); setAssingedLoading(task.id) }}
                                                                        >
                                                                            <option value="">-- Assigned to --</option>
                                                                            {teamMembers &&
                                                                                teamMembers.map((member) => (
                                                                                    <option key={member.employeeId} value={member.employeeId} selected={member.employeeId === (task.answerObject[0] && task.answerObject[0].executor) ? true : false}>
                                                                                        {member.employeeName}
                                                                                    </option>
                                                                                ))}
                                                                        </select>
                                                                    }
                                                                </>}




                                                            </td>
                                                            <td>
                                                                {task.lastModified}
                                                            </td>
                                                        </tr>
                                                    )}{" "}
                                                </>
                                            ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Card>
                    ) : (
                        <TaskDetailsComponents
                            taskList={taskList}
                            isOpneTaskListDetails={isOpneTaskListDetails}
                            workflowMainCategory={props.workflowMainCategory}
                            workflowPhases={props.workflowPhases}
                            selectedWorkflowMasterId={props.selectedWorkflowMasterId}
                            currentPhaseId={currentPhaseId}
                            taskDetails={taskDetails}
                            handleBackTaskList={handleBackTaskList}
                            setCurrentPhaseId={setCurrentPhaseId}
                            statusList={statusList}
                            setTaskDetails={setTaskDetails}
                            setTaskList={setTaskList}
                            selectedStoreData={props.selectedStoreData}
                            teamMembers={teamMembers}
                            setWorkflowPhaseCount={setWorkflowPhaseCount}
                            setTotalWorkflowPhaseCount={setTotalWorkflowPhaseCount}
                        />
                    )}
                </>
            ) : (
                <TaskListDetailsPriviewModeComponents
                    workflowPhases={props.workflowPhases}
                    workflowMainCategory={props.workflowMainCategory}
                    selectedWorkflowMasterId={props.selectedWorkflowMasterId}
                    currentPhaseId={currentPhaseId}
                    taskList={taskList}
                    statusList={statusList}
                    setStatusList={setStatusList}
                    selectedStoreData={props.selectedStoreData}
                    teamMembers={teamMembers}
                    setWorkflowPhaseCount={setWorkflowPhaseCount}
                    setTotalWorkflowPhaseCount={setTotalWorkflowPhaseCount}
                    taskDetails={taskDetails}
                    setTaskList={setTaskList}
                    setTaskDetails={setTaskDetails}
                />
            )}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        storeOverview: state.accountOverview.store.storeOverview,
        storeProducts: state.accountOverview.store.storeProducts,
        newUser: state.loggedUser.newUser,
        needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
        user: state.LWA.user,
        isAuth: state.LWA.isAuth,
        enableMobileMenu: state.theme.enableMobileMenu,
        enableClosedSidebar: state.theme.enableClosedSidebar,
        adProfiles: state.accountOverview.landing.adProfiles,
    };
};

export default connect(mapStateToProps)(WorkflowPhasesPage);
