import React, { Fragment, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Input,
    Label,
    Row,
} from "reactstrap";
import { connect } from "react-redux";
import Search from "./../../../../../../assets/img/StoreOverview_Img/search.svg";
import Campaign from "./../../../../../../assets/img/StoreOverview_Img/campaign.svg";
import Target from "./../../../../../../assets/img/StoreOverview_Img/target.svg";
import Weekly from "./../../../../../../assets/img/StoreOverview_Img/weekly.svg";
import Product from "./../../../../../../assets/img/StoreOverview_Img/product.svg";
import Campaigns from "../../../../../../designUtils/Storeoverview/Reports/Campaigns.svg"
import SearchTerms from "../../../../../../designUtils/Storeoverview/Reports/SearchTerms.svg"
import Targeting from "../../../../../../designUtils/Storeoverview/Reports/Targeting.svg"
import WeeklySales from "../../../../../../designUtils/Storeoverview/Reports/WeeklySales.svg"
import ProductPerformance from "../../../../../../designUtils/Storeoverview/Reports/ProductPerformance.svg"

import CustomModal from "../../../../../Components/Modals/CustomModal";
import PowerbiEmbed from "../../Components/PowerBI/PowerbiEmbed";
function StoreOverviewReports(props) {
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [store, setStore] = useState(props.adProfiles[0]);
    const [reportName, setReportName] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [report, setReport] = useState("report4");

    var report1, report2, report3, report4, report5;

    if (store) {
        switch (report) {
            case "report1":
                var table = "AdvCampaignReportsSummary";
                var column = "IntegerId";
                report1 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.CampaignPerformanceReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report2":
                var table = "SearchTerm_Report";
                var column = "ProfileId";
                console.log(props.WeeklySearchTermReport);
                report2 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.WeeklySearchTermReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report3":
                var table = "Merge1";
                var column = "AdvTargetingSummary.ProfileId";
                report3 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.TargetPerformanceReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report4":
                var table = "newamazonadvertiserprofile";
                var column = "integerID";
                report4 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.WeeklySalesReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report5":
                var table = "Merge2_SPAPI_Asin_sku+Merge1_Summary";
                var column = "ProfileId";
                report5 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.ProductPerformanceReport}
                        profileId={props.profileId}
                    />
                );
                break;

            default:
                var pageContent = <div>Report not found</div>;
        }
    }

    useEffect(() => {
        setSelectedProducts([]);
    }, [store]);

    const getCampaignPerformanceReport = () => {
        setReport("report1");
    };
    const getWeeklySearchTermReport = () => {
        setReport("report2");
    };
    const getTargetPerformanceReport = () => {
        setReport("report3");
    };
    const getWeeklySalesReport = () => {
        setReport("report4");
    };
    const getProductPerformanceReport = () => {
        setReport("report5");
    };

    const reportPage = () => (
        <>

        

<Container className='ml-2'>
      <Row>
        <Col md={4}>
        <a onClick={getWeeklySalesReport}>
          <Card className='reportTextBackground card-of-problemarea'>
            <CardBody className='d-flex'>
            <Col md="4" className='reportImgBackground' >
                <img className='mt-3 mb-3 ml-2' src={WeeklySales}/>
            </Col>
            <Col md={8} className='reportTextBackground'>
                    <h6 className="text-center mt-3 ">
                        <b>Weekly Sales</b>
                    </h6>
            </Col>  
            </CardBody >
          </Card>
          </a>
        </Col>

        <Col md={4}>
        <a onClick={getProductPerformanceReport}>
          <Card className='reportTextBackground card-of-problemarea'>
            <CardBody className='d-flex'>
                 <Col md="4" className='reportImgBackground' >
                    <img  className='mt-3 mb-3 ml-2' src={ProductPerformance}/>
                </Col>
                <Col md={8}>
                        <h6 className="text-center mt-3">
                            <b>Product Performance</b>
                        </h6>
                </Col>
            </CardBody>
          </Card>
          </a>
        </Col>

        <Col md={4}>
        <a onClick={getCampaignPerformanceReport}>
          <Card className='reportTextBackground card-of-problemarea'>
            <CardBody className='d-flex'>
            <Col md="4" className='reportImgBackground'>
                <img  className='mt-3 mb-3 ml-2' src={Campaigns}/>
            </Col>
            <Col md={8}>
                    <h6 className="text-center mt-3">
                        <b>Campaigns</b>
                    </h6>
            </Col>
            </CardBody>
          </Card>
          </a>
        </Col>
      </Row>

      <Row className="mt-3 justify-content-center card-of-problemarea">
        {/* Second Row */}
        <Col md={4} >
        <a onClick={getWeeklySearchTermReport}>
          <Card className='reportTextBackground card-of-problemarea'>
            <CardBody className='d-flex'>
            <Col md="4" className='reportImgBackground' >
                <img className='mt-3 mb-3 ml-2' src={SearchTerms}/>
            </Col>
            <Col md={8}>
           
                    <h6 className="text-center mt-3">
                        <b>Search Terms</b>
                    </h6>
            </Col>
            </CardBody>
          </Card>
          </a>

        </Col>

        <Col md={4}>
        <a onClick={getTargetPerformanceReport}>
          <Card className='reportTextBackground card-of-problemarea'>
            <CardBody className='d-flex'>
            <Col md="4" className='reportImgBackground'>
            <img  className='mt-3 mb-3 ml-2' src={Targeting}/>
        </Col>
        <Col md={8}>
                <h6 className="text-center mt-3">
                    <b>Targeting</b>
                </h6>
        </Col>
            </CardBody>
          </Card>
          </a>
        </Col>
       
      </Row>
    </Container>
          <Fragment>
            <Col md="12" className="p-0 mt-4">
                <Col md="12" className="p-0">
                    {report4?<Label className="w-100">
                        <span> {report4} </span>
                    </Label>:''}
                    {report5?<Label className="w-100">
                        <span> {report5} </span>
                    </Label>:''}
                    {report1?<Label className="w-100">
                        <span> {report1} </span>
                    </Label>:''}
                    {report2?<Label className="w-100">
                        <span> {report2} </span>
                    </Label>:''}
                    {report3?<Label className="w-100">
                        <span> {report3}  </span>
                    </Label>:''}
                </Col>
            </Col>
            <CustomModal
                header={reportName}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={
                    <>
                        <Button color={"link"} onClick={() => setIsOpen(!isOpen)}>
                            Cancel
                        </Button>
                    </>
                }
            ></CustomModal>
        </Fragment>
        </>
      
    );

    return (
        <Fragment>
            <div>
                <Container fluid>
                    <Row>{reportPage()}</Row>
                </Container>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    token: state.loggedUser.token,
    adProfiles: state.accountOverview.landing.adProfiles,
    accessLevel: state.loggedUser.accessLevel,
    // PowerBI reports
    ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport: state.accountOverview.store.TargetPerformanceReport,
    StoreOverviewReport: state.accountOverview.store.StoreOverviewReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    WeeklySearchTermReport: state.accountOverview.store.WeeklySearchTermReport,
    CampaignPerformanceReport: state.accountOverview.store.CampaignPerformanceReport,
});

export default connect(mapStateToProps)(StoreOverviewReports);
