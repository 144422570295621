import React, { useCallback, useEffect, useState } from "react";
import GetWorkflowMainCategoryService from "../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowMainCategoryService";
import { connect } from "react-redux";
import { Button, Card, Col, Container, Row } from "reactstrap";
import WorkflowPhasesPage from "./Components/WorkflowPhasesPage";
import { useHistory } from "react-router-dom";
function WorkflowsMainPage(props) {
  const [selectedStore, setSelectedStore] = useState("");
  const [workflowMainCategory, setWorkflowMainCategory] = useState("");
  const [workflowPhases, setWorkflowPhases] = useState("");
  const [workflowPhasesPage, setWorkflowPhasesPage] = useState(false);
  const [selectedWorkflowMasterId, SetSelectedWorkflowMasterId] = useState();
  const [selectedStoreData, setSelectedStoreData] = useState({
    integerId: "",
    storeName: "",
  });

  useEffect(() => {
    GetWorkflowMainCategoryService(props.token).then((res) => {
      if (res) {
        console.log(res);
        setWorkflowMainCategory(res.masterWorkflows);
        setWorkflowPhases(res.workflowPhases);
      }
    });
  }, []);

  const handleStoreChange = (e) => {
    console.log(e.target.value);
    props.adProfiles.map((store) => {
      if (e.target.value == store.integerID) {
        setSelectedStore(e.target.value);
        console.log(e.target.value)
        setSelectedStoreData({
          integerId: store.integerID,
          storeName: store.name
        });
      }
    });
  };
  const handleWorkflowMaster = (e) => {
    SetSelectedWorkflowMasterId(e.target.value);
  };

  const handleBackButtonToWorkflow = () => {
    setWorkflowPhasesPage(false);
    SetSelectedWorkflowMasterId(null)
    setSelectedStore({integerID:''})
    setSelectedStoreData(null)
  };
  return (
    <>
      <Container>
        {!workflowPhasesPage ? (
          <Row>
            <Col md={12}>
              <Card className=" p-4 card-of-problemarea">
                <Row>
                  <Col md={6}>
                    <select
                      id="StoreList"
                      name="StoreList"
                      onChange={(e) => handleStoreChange(e)}
                      value={selectedStore.integerID}
                      className="form-control"
                    >
                      <option value="">--Select Store--</option>
                      {props.adProfiles.map((store) => (
                        <option value={store.integerID}> {store.name}</option>
                      ))}
                    </select>
                  </Col>
                  <Col md={6}>
                    <select
                      onChange={(e) => handleWorkflowMaster(e)}
                      value={selectedWorkflowMasterId}
                      className="form-control"
                    >
                      <option value="">--Select Workflow--</option>
                      {workflowMainCategory &&
                        workflowMainCategory.map((wf) => (
                          // <option value={wf.id} > {wf.name}</option>
                          <option value={wf.id} disabled={!selectedStore || !selectedStoreData}> {wf.name}</option>
                        ))}
                    </select>
                  </Col>

                  <Col md={12}>
                    <p className="text-center mt-3">
                      <Button
                        className="btn-background-color"
                        onClick={() => setWorkflowPhasesPage(true)}
                        disabled={!selectedWorkflowMasterId || !(selectedStore, selectedStoreData )}
                      >
                        Submit
                      </Button>
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <WorkflowPhasesPage
            handleBackButtonToWorkflow={handleBackButtonToWorkflow}
            selectedWorkflowMasterId={selectedWorkflowMasterId}
            workflowPhases={workflowPhases}
            workflowMainCategory={workflowMainCategory}
            selectedStoreData={selectedStoreData}
          />
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    adProfiles: state.accountOverview.landing.adProfiles,
  };
};

export default connect(mapStateToProps)(WorkflowsMainPage);
