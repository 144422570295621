/** Determines whether the webapp is run in a production or development environment */
const ENV = {
  PRODUCTION: "PRODUCTION",
  DEVELOPMENT: "DEVELOPMENT",
  LOCAL: "LOCAL",
}

/** API URLs */
const API_URL = {
  PRODUCTION: "https://api.app.simpliworks.io",
  DEVELOPMENT: "https://api.dev.simpliworks.io",
  RAIDEN: "https://api.raiden.simpliworks.io",
  SCORPION: "https://api.scorpion.simpliworks.io",
  LOCAL: "http://localhost:8000",
}

const hostname = window.location.hostname

let CURRENT_ENV, CURRENT_API_URL
switch (hostname) {
  case "app.simpliworks.io":
    CURRENT_ENV = ENV.PRODUCTION
    CURRENT_API_URL = API_URL.PRODUCTION
    break
  case "dev.simpliworks.io":
  case "raiden.simpliworks.io":
  case "scorpion.simpliworks.io":
    CURRENT_ENV = ENV.DEVELOPMENT
    if (hostname === "raiden.simpliworks.io") CURRENT_API_URL = API_URL.RAIDEN
    else if (hostname === "scorpion.simpliworks.io")
      CURRENT_API_URL = API_URL.SCORPION
    else CURRENT_API_URL = API_URL.DEVELOPMENT
    break
  default:
    CURRENT_ENV = ENV.LOCAL
    CURRENT_API_URL = API_URL.LOCAL
}

/** LWA */
const LWA_CLIENT_ID =
  "amzn1.application-oa2-client.52885b7eb41d4e9e9d535ade8616a5af"

const LWA_CLIENT_SECRET =
  "6c43ac16b00cc1503ae9fc7a83245e66a52516b0d8820e558e11dc2ff6d6c4a9"

/** Seller Partner API */
const SPAPI_APP_ID = "amzn1.sp.solution.ab86d6e2-5dfc-4508-bff1-b293307026f6"

/** Stripe Production Keys */
const STRIPE_PK = {
  TEST: "pk_test_R4OHWSiGkOLSZFTPpIWkR4O500PGOAQPXu",
  LIVE: "pk_live_IxljfHgHVMyoJBiUVkSaSltW00Kpf9G81w",
}

// const CURRENT_STRIPE_PK = "pk_live_IxljfHgHVMyoJBiUVkSaSltW00Kpf9G81w"
const CURRENT_STRIPE_PK =
  CURRENT_ENV === ENV.PRODUCTION ? STRIPE_PK.LIVE : STRIPE_PK.TEST
//CURRENT_ENV === ENV.PRODUCTION ? STRIPE_PK.LIVE : STRIPE_PK.TEST

/** Customer Support Email */
const CUSTOMER_SUPPORT_EMAIL = "developer@simpliworks.io"

/** Seller Marketplaces */
const COUNTRIES = ["USA", "Canada", "Mexico"]

const Editor = {}

Editor.modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["link", "video", "image"], // To Add Image and Video  ['link', 'image', 'video'],
    // ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "background",
  "code-block",
  "script",
  "align",
  "image",
  "video"// 'image', 'video',  - To White list image and video
]

export {
  LWA_CLIENT_ID,
  LWA_CLIENT_SECRET,
  SPAPI_APP_ID,
  CURRENT_STRIPE_PK,
  CURRENT_API_URL,
  CUSTOMER_SUPPORT_EMAIL,
  COUNTRIES,
  CURRENT_ENV,
  Editor,
}
