import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardTitle,
} from "reactstrap";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";
import DateRangePicker from "../../../../../../Components/Input/DateRangePicker";
import { GetAdvReportsStatusService } from "../../../../../../../Service/OPSServices/GetAdvReportsStatusService";
import { RetryParticularAdvReportService } from "../../../../../../../Service/OPSServices/RetryParticularAdvReportService";

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { saveAdvSPAPISummariesService } from "../../../../../../../Service/OPSServices/saveAdvSPAPISummariesService";
import { fetchAdvSPAPISummariesService } from "../../../../../../../Service/OPSServices/fetchAdvSPAPISummariesService";
 

function AdvReportsStatus(props) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [integerId, setIntegerId] = useState("");
  const [asin, setAsin] = useState("");

  const [loading, setLoading] = useState(false);
  const [advertisingDataOpen, setAdvertisingDataOpen] = useState(false);
  const [allReports, setAllReports] = useState()
  const [advertisingReportData,SetAdvertisingReportData] =useState()
  const[campaignNamePop,setCampaignNamePop] =useState(false)
  const[campaignName,setCampaignName] =useState()
  
  const isInputValid = () => {
    if (dateRange.startDate === null || integerId == "") return false;
    return true;
  };
  const fetchAdvSPAPISummaries= (reportName) =>{ 
    
    let data = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      integerId: integerId,
      reportName:reportName 
  };

  fetchAdvSPAPISummariesService(props.token,data).then((res)=>{
    if(res)
    {
      console.log(res.reports)
      SetAdvertisingReportData(res.reports)
    }
    
  })
}
  const saveAdvSPAPISummaries = () =>{
      let data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        integerId: integerId
    };

    saveAdvSPAPISummariesService(props.token,data).then((res)=>{
      if(res.status)
      {
        console.log(res)
      }
    })
  }
  const fetchAdvReportsStatus = (dataType) => {
    setLoading(true);

    let data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        integerId: integerId,
        dataType:dataType
    };

    GetAdvReportsStatusService(props.token, data).then((response) => {
      if (response) {
        console.log(response);
        setAllReports(response.allReports["missing_SPAPI_Reports"])
        //let fileName = integerId +  dateRange.startDate + dateRange.endDate
      // Assuming startDate and endDate are JavaScript Date objects
        let startDateString = dateRange.startDate.toISOString().slice(0, 10);
        let endDateString =dateRange.endDate.toISOString().slice(0, 10);

        // Concatenate integerId and formatted date strings
        let fileName = integerId + "_" +response.allReports["store_Name"] + "_" +  response.allReports["sellerPartnerid"] + "_" + startDateString + "-" + endDateString;

      if ("store_summary" in response.allReports)
      {
          exportToCSV(response.allReports, fileName);}
      }
    });

    setLoading(false);
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const isValidArray = (data) => Array.isArray(data) && data.length > 0;

    // Check if each property in csvData is a valid array
    const ws = isValidArray(csvData.store_summary) ? XLSX.utils.json_to_sheet(csvData.store_summary) : null;
    const ws1 = isValidArray(csvData.spapi) ? XLSX.utils.json_to_sheet(csvData.spapi) : null;
    const ws2 = isValidArray(csvData.billingRecords) ? XLSX.utils.json_to_sheet(csvData.billingRecords) : null;
    const ws3 = isValidArray(csvData.asinManagementStateHisList) ? XLSX.utils.json_to_sheet(csvData.asinManagementStateHisList) : null;
    const ws4 = isValidArray(csvData.asin_data_list) ? XLSX.utils.json_to_sheet(csvData.asin_data_list) : null;
    const ws5 = isValidArray(csvData.missing_SPAPI_Reports) ? XLSX.utils.json_to_sheet(csvData.missing_SPAPI_Reports) : null;
    const ws6 = isValidArray(csvData.all_Asins) ? XLSX.utils.json_to_sheet(csvData.all_Asins) : null;

    // Build workbook with valid sheets
    const wb = {
        Sheets: {
            'store_summary': ws,
            'spapi': ws1,
            'billingRecords': ws2,
            'asinManagementStateHisList': ws3,
            'asin_data_list': ws4,
            'missing_SPAPI_Reports': ws5,
            'all_Asins' : ws6
        },
        SheetNames: [
            'store_summary',
            'spapi',
            'billingRecords',
            'asinManagementStateHisList',
            'asin_data_list',
            'missing_SPAPI_Reports',
            'all_Asins'
        ]
    };

    // Remove null sheets from workbook
    wb.SheetNames = wb.SheetNames.filter(sheetName => wb.Sheets[sheetName] !== null);
    wb.Sheets = wb.SheetNames.reduce((obj, sheetName) => {
        obj[sheetName] = wb.Sheets[sheetName];
        return obj;
    }, {});

    // Convert workbook to Excel buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    // Save Excel file
    FileSaver.saveAs(data, fileName + fileExtension);
};



  const [jsonData, setJsonData] = useState();
  const [isJsonOpen, setIsJsonOpen] = useState();
  const [parsedJsonData, setParsedJsonData] = useState();
  const [reports,setReports]=useState('report1')

  const printTheJSONInPrettyFormat = (inputJson) => {
    var parseJSON = JSON.parse(inputJson);

    var JSONInPrettyFormat = JSON.stringify(parseJSON, undefined, 4);
    setParsedJsonData(parseJSON);
    setJsonData(JSONInPrettyFormat);
  };

  const retryParticularAdvReport = (value) =>{
    setLoading(true);

    let data = {
        reportDate: value.date,
      reportName: value.reportName     
    };

    RetryParticularAdvReportService(props.token, data).then((response) => {
      if (response) {
        console.log(response);
      }
    });

    setLoading(false);
  }
  const handaleName=(name)=>{
    setCampaignNamePop(true)
    setCampaignName(name)
  }
  const columns = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
        Header: "Report Name",
        accessor: "reportName",
    },
    {
      Header: "Report Status",
      accessor: "reportStatus",
    },
    {
      Header: "Report JSON",
      accessor: "reportJson",
        Cell: (cellInfo) => (
        <Button
          className="m-auto"
          color={"link"}
          onClick={() => {
            printTheJSONInPrettyFormat(cellInfo.value);
            setIsJsonOpen(!isJsonOpen);
          }}
        >
          View
        </Button>
      ),
    },
    {
        Header: "Action",
        Cell: (cellInfo) => (
        <Button
          className="m-auto"
          color={"link"}
          onClick={() => {
            // printTheJSONInPrettyFormat(cellInfo.original);
            // setIsJsonOpen(!isJsonOpen);
            retryParticularAdvReport(cellInfo.original)
          }}
        >
          Fetch Again
        </Button>
      ),
    },
  ];
 const advertisingproductReportData=[
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
  {
    Header: "Advertised Asin",
    accessor: "advertisedAsin",
  },
  {
    Header:'State',
    accessor:'simpliworksManagementState',
  },
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Clicks",
    accessor: "clicks",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Cost',
    accessor:'cost',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Spend",
    accessor: "spend",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Campaign Budget Amt",
    accessor: "campaignBudgetAmount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Purchases7d',
    accessor:'purchases7d',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Sales7d',
    accessor:'sales7d',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  // {
  //   Header:'Created By',
  //   accessor:'createdBy',
  // },
  // {
  //   Header:'Created At',
  //   accessor:'createdAt',
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
  
 ]
 const campaigngroupedbycampaignReportData=[
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
  {
    Header:'Campaign Name',
    accessor:'campaignName',
    Cell:(cellInfo)=>(
      <div className="d-flex justify-content-center w-100">
        <p onClick={()=>handaleName(cellInfo.value)}className="comment-paragraph-reports">{cellInfo.value}</p>
      </div>
    )
  },
  {
    Header: "Campaign Status",
    accessor: "campaignStatus",
  },
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Clicks",
    accessor: "clicks",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Spend",
    accessor: "spend",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Cost',
    accessor:'cost',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
 
  {
    Header: "Campaign Budget Amt",
    accessor: "campaignBudgetAmount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  
  // {
  //   Header: "campaign Id",
  //   accessor: "campaignId",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  
 
 
  {
    Header:'Purchases7d',
    accessor:'purchases7d',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Sales7d',
    accessor:'sales7d',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  // {
  //   Header:'Created By',
  //   accessor:'createdBy',
  // },
  // {
  //   Header:'Created At',
  //   accessor:'createdAt',
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
 ]
 const sponsoredDisplayAdvertisedProductReportData=[
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
  {
    Header: "Advertised Asin",
    accessor: "promotedAsin",
  },
  // {
  //   Header: "Promoted Asin",
  //   accessor: "promotedAsin",
  // },
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Units Sold',
    accessor:'unitsSold',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Clicks',
    accessor:'clicks',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Cost',
    accessor:'cost',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'State',
    accessor:'simpliworksManagementState',
  },
  {
    Header:'Sales',
    accessor:'sales',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Purchases',
    accessor:'purchases',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  // {
  //   Header:'Created By',
  //   accessor:'createdBy',
  // },
  // {
  //   Header:'Created At',
  //   accessor:'createdAt',
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
  
 ]
 const sponsoredBrandsCampaigngroupedbycampaignReportData=[
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
  {
    Header:'Campaign Name',
    accessor:'campaignName',
    Cell:(cellInfo)=>(
      <div className="d-flex justify-content-center w-100">
        <p onClick={()=>handaleName(cellInfo.value)}className="comment-paragraph-reports">{cellInfo.value}</p>
      </div>
    )
  },
  {
    Header: "Campaign Status",
    accessor: "campaignStatus",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Units Sold',
    accessor:'unitsSold',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Campaingn Budget Amt",
    accessor: "campaignBudgetAmount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  // {
  //   Header: "Campaign Id",
  //   accessor: "campaignId",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  {
    Header: "Clicks",
    accessor: "clicks",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Cost',
    accessor:'cost',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Purchases',
    accessor:'purchases',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Sales',
    accessor:'sales',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  
  // {
  //   Header:'Created By',
  //   accessor:'createdBy',
  // },
  // {
  //   Header:'Created At',
  //   accessor:'createdAt',
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
 ]
 const sponsoredDisplayCampaigngroupedbycampaignReportData=[
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
  {
    Header:'Campaign Name',
    accessor:'campaignName',
    Cell:(cellInfo)=>(
      <div className="d-flex justify-content-center w-100">
        <p onClick={()=>handaleName(cellInfo.value)}className="comment-paragraph-reports">{cellInfo.value}</p>
      </div>
    )
  },
  {
    Header: "Campaign Status",
    accessor: "campaignStatus",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Units Sold',
    accessor:'unitsSold',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Campaingn Budget Amt",
    accessor: "campaignBudgetAmount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  // {
  //   Header: "Campaign Id",
  //   accessor: "campaignId",
  // },
  {
    Header: "Clicks",
    accessor: "clicks",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Cost',
    accessor:'cost',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Purchases',
    accessor:'purchases',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Sales',
    accessor:'sales',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
 
  // {
  //   Header:'Created By',
  //   accessor:'createdBy',
  // },
  // {
  //   Header:'Created At',
  //   accessor:'createdAt',
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
 ]
 const sponsoredTelevisionCampaigngroupedbycampaignReportData=[
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Campaign Name',
    accessor:'campaignName',
    Cell:(cellInfo)=>(
      <div className="d-flex justify-content-center w-100">
        <p onClick={()=>handaleName(cellInfo.value)}className="comment-paragraph-reports">{cellInfo.value}</p>
      </div>
    )

  },
  // {
  //   Header: "Campaign Id",
  //   accessor: "campaignId",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Clicks",
    accessor: "clicks",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Campaingn Rule Budget",
    accessor: "campaignRuleBasedBudgetAmount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Purchases7d',
    accessor:'purchases7d',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Campaign Status",
    accessor: "campaignStatus",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Cost',
    accessor:'cost',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
 
  {
    Header:'Sales7d',
    accessor:'sales7d',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },

  // {
  //   Header:'Created By',
  //   accessor:'createdBy',
  // },
  // {
  //   Header:'Created At',
  //   accessor:'createdAt',
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
 ]
 const sPAPIStoreSales=[
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
 
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Unit Count ",
    accessor: "unitCount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Order Item Count",
    accessor: "orderItemCount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Order Count",
    accessor: "orderCount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Avg Unit Price',
    accessor:'averageUnitPriceAmount',
  Cell: (cellInfo) => (
    <div className="d-flex justify-content-center w-100">
      <p className="m-0">{cellInfo.value+' '+cellInfo.original.averageUnitPriceCurrencyCode}</p>
    </div>
  ),
  },
 
  // {
  //   Header: "Avg Unit Price",
  //   accessor: "averageUnitPriceCurrencyCode",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value+cellInfo.original.averageUnitPriceAmount}</p>
  //     </div>
  //   ),
  // },
  {
    Header:'Total Sales',
    accessor:'totalSalesAmount',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value +' '+cellInfo.original.totalSalesCurrencyCode}</p>
      </div>
    ),
  },
  // {
  //   Header:'Total Sales Currency Code',
  //   accessor:'totalSalesCurrencyCode',
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  
  // {
  //   Header:'CreatedBy',
  //   accessor:'createdBy',
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  // {
  //   Header:'CreatedAt',
  //   accessor:'createdAt'
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
 ]
 const sPAPIAsinSales =[  
  {
    Header: "Report Date",
    accessor: "reportDate",
  },
  {
    Header: "Integer Id",
    accessor: "integerId",
    width:130,
  },
  {
    Header: "Asin",
    accessor: "asin",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "State",
    accessor: "simpliworksManagementState",
  },
  {
    Header: "Week Number",
    accessor: "week_number",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Month",
    accessor: "month",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  
  {
    Header: "Unit Count ",
    accessor: "unitCount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Order Item Count",
    accessor: "orderItemCount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header: "Order Count",
    accessor: "orderCount",
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value}</p>
      </div>
    ),
  },
  {
    Header:'Avg Unit Price',
    accessor:'averageUnitPriceAmount',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value+' ' +cellInfo.original.averageUnitPriceCurrencyCode}</p>
      </div>
    ),
  },
  // {
  //   Header: "Average Unit Price Currency Code",
  //   accessor: "averageUnitPriceCurrencyCode",
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  // {
  //   Header:'Total Sales Currency Code',
  //   accessor:'totalSalesCurrencyCode',
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  {
    Header:'Total Sales',
    accessor:'totalSalesAmount',
    Cell: (cellInfo) => (
      <div className="d-flex justify-content-center w-100">
        <p className="m-0">{cellInfo.value +' '+ cellInfo.original.totalSalesCurrencyCode}</p>
      </div>
    ),
  },
  // {
  //   Header:'Total Sales Currency Code',
  //   accessor:'totalSalesCurrencyCode',
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  // {
  //   Header:'CreatedBy',
  //   accessor:'createdBy',
  //   Cell: (cellInfo) => (
  //     <div className="d-flex justify-content-center w-100">
  //       <p className="m-0">{cellInfo.value}</p>
  //     </div>
  //   ),
  // },
  // {
  //   Header:'CreatedAt',
  //   accessor:'createdAt'
  // },
  {
    Header:'Updated At',
    accessor:'updatedAt',
  },
 ]
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <CardBody>
            <CardTitle>Date Range</CardTitle>
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <div className="divider" />
            <Label for={"storeID"}>Store Integer ID</Label>
            <Input
              className="mb-3"
              id={"storeID"}
              value={integerId}
              onChange={(e) => setIntegerId(e.target.value)}
              placeholder="Enter Store ID"
            />
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() => fetchAdvReportsStatus('AdvertisingData')}
              //disabled={!isInputValid()}
            >
              Advertising Data
            </LoadingButton>
            &nbsp;
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() => fetchAdvReportsStatus('SPAPIData')}
              //disabled={!isInputValid()}
            >
              SPAPI Data
            </LoadingButton>
            &nbsp;
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() => saveAdvSPAPISummaries()}
              //disabled={!isInputValid()}
            >
              Save SPAPI Summaries
            </LoadingButton>
            &nbsp;
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() =>{setAdvertisingDataOpen(true);fetchAdvSPAPISummaries('Advertised product report')}}
              //disabled={!isInputValid()}
            >
              View SPAPI Summaries
            </LoadingButton>
          </CardBody>
        </Col>
      </Row>

      <ReactTable
        data={allReports}
        columns={columns}
        noDataText={"No Data Found."}
        className="-fixed -highlight -striped"
        defaultPageSize={5}
      />

      <CustomModal
        header={"Report status"}
        isOpen={isJsonOpen}
        toggle={() => setIsJsonOpen(!isJsonOpen)}
        size={"md"}
        className="curve-popup"
        footer={
          <Fragment>
            <Button color="link" onClick={() => setIsJsonOpen(!isJsonOpen)}>
              Cancel
            </Button>
          </Fragment>
        }
      >
        <Label>
          <p>
            <pre style={{ whiteSpace: "pre-wrap" }}>{jsonData}</pre>
          </p>
        </Label>
      </CustomModal>
      <CustomModal
        header={"Campaign Name"}
        isOpen={campaignNamePop}
        toggle={() => setCampaignNamePop(!campaignNamePop)}
        size={"md"}
        className="curve-popup"
      >
        <Label>
          <p>{campaignName}</p>
        </Label>
      </CustomModal>
      <CustomModal
        header={"Report status"}
        isOpen={advertisingDataOpen}
        toggle={() => setAdvertisingDataOpen(!advertisingDataOpen)}
        size={"xl"}
        className="curve-popup"
      >
        <Card className=' p-3 card-of-problemarea'>
        <div className="d-flex">
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('Advertised product report');setReports('report1')}}><b>SP Advertised Product</b></h5>
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('Campaign grouped by campaign');setReports('report2')}}><b>SP Campaign</b></h5>
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('Sponsored Display Advertised Product Report');setReports('report3')}}><b>SD Advertised Product</b></h5>
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('Sponsored Brands Campaign grouped by campaign');setReports('report4')}}><b>SB Campaign</b></h5>
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('Sponsored Display Campaign grouped by campaign');setReports('report5')}}><b>SD Campaign</b></h5>
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('Sponsored Television Campaign grouped by campaign');setReports('report6')}}><b>ST Campaign</b></h5>
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('SPAPI Store Sales');setReports('report7')}}><b>SPAPI Store Sales</b></h5>
          <h5 className="advertising-report-fetch" onClick={()=>{fetchAdvSPAPISummaries('SPAPI Asin Sales');setReports('report8')}}><b>SPAPI Asin Sales</b></h5>
        </div>
        </Card>
        
        <Card className=' p-3 card-of-problemarea mt-3'>
        {reports =='report1' &&
         <ReactTable
         data={advertisingReportData}
         columns={advertisingproductReportData}
         noDataText={"No Data Found."}
         className="-fixed -highlight -striped"
         defaultPageSize={5}
       /> }
        {reports =='report2' &&
        <ReactTable
        data={advertisingReportData}
        columns={campaigngroupedbycampaignReportData}
        noDataText={"No Data Found."}
        className="-fixed -highlight -striped"
        defaultPageSize={5}
      />}
          {reports =='report3' &&
        <ReactTable
        data={advertisingReportData}
        columns={sponsoredDisplayAdvertisedProductReportData}
        noDataText={"No Data Found."}
        className="-fixed -highlight -striped"
        defaultPageSize={5}
      />}
          {reports =='report4' &&
        <ReactTable
        data={advertisingReportData}
        columns={sponsoredBrandsCampaigngroupedbycampaignReportData}
        noDataText={"No Data Found."}
        className="-fixed -highlight -striped"
        defaultPageSize={5}
      />}
          {reports =='report5' &&
        <ReactTable
        data={advertisingReportData}
        columns={sponsoredDisplayCampaigngroupedbycampaignReportData}
        noDataText={"No Data Found."}
        className="-fixed -highlight -striped"
        defaultPageSize={5}
      />}
           {reports =='report6' &&
        <ReactTable
        data={advertisingReportData}
        columns={sponsoredTelevisionCampaigngroupedbycampaignReportData}
        noDataText={"No Data Found."}
        className="-fixed -highlight -striped"
        defaultPageSize={5}
      />}
      {reports == 'report7'&&
       <ReactTable
       data={advertisingReportData}
       columns={sPAPIStoreSales}
       noDataText={"No Data Found."}
       className="-fixed -highlight -striped"
       defaultPageSize={5}
     />
      }
      {reports == 'report8'&&
       <ReactTable
       data={advertisingReportData}
       columns={sPAPIAsinSales}
       noDataText={"No Data Found."}
       className="-fixed -highlight -striped"
       defaultPageSize={5}
     />
      }
        </Card>
      </CustomModal>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  token: state.loggedUser.token,
});

export default connect(mapStateToProps)(AdvReportsStatus);
