import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';

function fetchAdvSPAPISummariesService(token, data) {
    
    return axios.post(`${CURRENT_API_URL}/sellerPartner/fetchAdvSPAPISummaries`,data, {
        
        headers: {
            'Authorization': token,
        }
    }).then(res => {
                notify.NotifySuccess("All advertising SPAPI reports summaries fetched.");
                return res.data;
            }).catch(() => {
                notify.NotifyError("An error occurred while fetching advertising SPAPI reports summaries.");
                return false;
            });
}
export {
    fetchAdvSPAPISummariesService,
}