import React, {useState,useEffect} from 'react';
import {Card, CardBody, CardTitle, Col, Container, Input, Label, Row, Button} from "reactstrap";
import { connect } from 'react-redux';
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import AllJobsListDataTable from './Components/AllJobsListDataTable';
import { FetchPendingReportsV3Service } from '../../../../../../Service/OPSServices/FetchPendingReportsV3Service';
import { FetchErrorReportsV3Service } from '../../../../../../Service/OPSServices/FetchErrorReportsV3Service';
import {RequestDailyAdvV3ReportsService} from '../../../../../../Service/OPSServices/RequestDailyAdvV3ReportsService';
import { RequestHistDataAdvReportsTaskService } from '../../../../../../Service/OPSServices/RequestHistDataAdvReportsTaskService';
import { SaveSummaryReportsV3Service } from '../../../../../../Service/OPSServices/SaveSummaryReportsV3Service';
import { SaveAllAdvReportsTaskService } from '../../../../../../Service/OPSServices/SaveAllAdvReportsTaskService';
import { UpdateProfilesV3Service } from '../../../../../../Service/OPSServices/UpdateProfilesV3Service';
import { UpdateAllAsinSkusService } from '../../../../../../Service/OPSServices/UpdateAllAsinSkusService';
import { UpdateAllAsinSkusTaskService } from '../../../../../../Service/OPSServices/UpdateAllAsinSkusTaskService';
import { UpdateStoreProductsV3Service } from '../../../../../../Service/OPSServices/UpdateStoreProductsV3Service';
import { PurgeProductsV3Service } from '../../../../../../Service/OPSServices/PurgeProductsV3Service';
import { RetryErrorReportV3Service } from '../../../../../../Service/OPSServices/RetryErrorReportV3Service';
import {GetHistSPAPIDataTaskService} from  "../../../../../../Service/OPSServices/GetHistSPAPIDataTaskService";
import {GetHistSPAPIDataService} from "../../../../../../Service/OPSServices/GetHistSPAPIDataService";
import {GetSellerPartnerListService} from "../../../../../../Service/OPSServices/GetSellerPartnerListService";
import {GetDataForLastOneDayService} from  "../../../../../../Service/OPSServices/GetDataForLastOneDayService";
import {GetCatalogueItemDetailsService} from  "../../../../../../Service/OPSServices/GetCatalogueItemDetailsService"
import {GetMerchantListingsService} from  "../../../../../../Service/OPSServices/GetMerchantListingsService"
import { DropdownList } from "react-widgets";
import CloseResolvedTroubleTicketsService from '../../../../../../Service/OPSServices/OPSTicketJobServices/CloseResolvedTroubleTicketsService';
import AdvReportsStatus from './Components/AdvReportsStatus';
function AdvertisingJobPage(props) {

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });
    const [email, setEmail] = useState("");

    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState([]);
    const [sellerPartnerId,setSellerPartnerId] = useState('');
    const [summary, setSummary] = useState();
    console.log(productStatus);

    const getDataForOneDay = () => {
        setLoading(true);        

        let data = {id : sellerPartnerId.id}
        
        GetDataForLastOneDayService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                setProductStatus(sellerPartnerDataStatus);
            }
        })

        setLoading(false);
    }

    const getHistSPAPIData = () => {
        setLoading(true);        

        let data = {id : sellerPartnerId.id, days: 15}
        
        GetHistSPAPIDataService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }
    
    const getLastDaySPAPIData = () => {
        setLoading(true);        

        let data = {id : sellerPartnerId.id,days: 1}
        
        GetHistSPAPIDataService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }


    const getLastYearSPAPIData = () => {
        setLoading(true);        

        let data = {id : sellerPartnerId.id,days: 365}
        
        GetHistSPAPIDataTaskService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }
    

    const getAllCatalogueItemDetailsSPAPIData = () => {
        setLoading(true);        

        let data = {id : 'All'}
        
        GetCatalogueItemDetailsService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })


        setLoading(false);
    }

    const getAllMerchantListings = () => {
        setLoading(true);        

        let data = {id : 'All'}
        
        GetMerchantListingsService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }

    const getMerchantListingsForSellerPartnerID = () => {
        setLoading(true);        

        let data = {id : sellerPartnerId.id}
        
        GetMerchantListingsService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }


    const getCatalogueItemDetailsSPAPIData = () => {
        setLoading(true);        

        let data = {id : sellerPartnerId.id}
        
        GetCatalogueItemDetailsService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })


        setLoading(false);
    }


    const get15DaysHistSPAPIDataTask = () => {
        setLoading(true);        

        let data = {id : 'All',days: 15}
        
        GetHistSPAPIDataTaskService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }

    const get30DaysHistSPAPIDataTask = () => {
        setLoading(true);        

        let data = {id : 'All',days: 30}
        
        GetHistSPAPIDataTaskService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }
    
    const getLastDaySPAPIDataTask = () => {
        setLoading(true);        

        let data = {id : 'All',days: 1}
        
        GetHistSPAPIDataTaskService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                console.log(sellerPartnerDataStatus)
            }
        })

        setLoading(false);
    }

    useEffect(() => {
        props.dispatch(GetSellerPartnerListService(props.token));        

    }, [])
    const RequestDailyAdvV3Reports = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
        
        RequestDailyAdvV3ReportsService(props.token, data).then(response => {
            if (response) {
                console.log(response);
                setProductStatus(response);
            }
        })

        setLoading(false);
    }


    const Request15DaysHistDataAdvReportsTask = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId, days: 15}
        
        RequestHistDataAdvReportsTaskService(props.token, data).then(response => {
            if (response) {
                console.log(response);
                setProductStatus(response);
            }
        })

        setLoading(false);
    }

    const Request30DaysHistDataAdvReportsTask = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId, days: 30}
        
        RequestHistDataAdvReportsTaskService(props.token, data).then(response => {
            if (response) {
                console.log(response);
                setProductStatus(response);
            }
        })

        setLoading(false);
    }



    const SaveSummaryReportsV3 = () => {
         setLoading(true);        

         let data = 
         {id : sellerPartnerId}
        
         SaveSummaryReportsV3Service(props.token, data).then(advertisingDataStatus => {
             if (advertisingDataStatus) {
                 console.log(advertisingDataStatus);
                 //setProductStatus(advertisingDataStatus);
             }
         })

         setLoading(false);
    }

    const SaveAllAdvReportsTask = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
       
        SaveAllAdvReportsTaskService(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);
                //setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
   }

    const FetchPendingReportsV3 = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
        
        FetchPendingReportsV3Service(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);
                setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }

    const FetchErrorReportsV3 = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
        
        FetchErrorReportsV3Service(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);
                setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }

    const UpdateProfilesV3 = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
        
        UpdateProfilesV3Service(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);
                setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }

    const UpdateAllSkus = () => {
        setLoading(true);        

        let data = 
        {id : ''}
        
        UpdateAllAsinSkusService(props.token, data).then(UpdateAllAsinSkusStatus => {
            if (UpdateAllAsinSkusStatus) {
                console.log(UpdateAllAsinSkusStatus);                
            }
        })

        setLoading(false);
    }

    const UpdateAllSkusTask = () => {
        setLoading(true);        

        let data = 
        {id : ''}
        
        UpdateAllAsinSkusTaskService(props.token, data).then(UpdateAllAsinSkusStatus => {
            if (UpdateAllAsinSkusStatus) {
                console.log(UpdateAllAsinSkusStatus);                
            }
        })

        setLoading(false);
    }


    const UpdateStoreProductsV3 = () => {
        setLoading(true);        

        let data = 
        {id : ''}
        
        UpdateStoreProductsV3Service(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);
                setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }

    const PurgeProductsV3 = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
        
        PurgeProductsV3Service(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);
                setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }

    const RetryErrorReportV3 = () => {
        setLoading(true);        

        let data = 
        {profileId : ''}
        
        RetryErrorReportV3Service(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);
                setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }
    const CloseResolvedTicket =()=>{
        CloseResolvedTroubleTicketsService(props.token).then(res=>{
            if(res)
            {
                console.log(res)
            }
        }
        )
    }
    return (
        <>

<Container fluid>
           
                <Row className="mb-3">
                   <Col md="12" lg="12">
                       <Card className="main-card mb-3">
                           <CardBody>
                           <CardTitle>Trouble Tickets</CardTitle>
                           
                               &nbsp;
                               <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={CloseResolvedTicket}                                    
                               >
                                   Close Resolved Tickets
                               </LoadingButton>
                                                           
                               
                               
                           </CardBody>
                       </Card>
                   </Col>
               </Row>
           &nbsp;
               <Row className="mb-3">
                   <Col md="12" lg="12">
                       <Card className="main-card mb-3">
                           <CardBody>
                           <CardTitle>User Profile Operations </CardTitle>
                           
                               &nbsp;
                               <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={UpdateAllSkus}                                    
                               >
                                   Update All Asin Skus
                               </LoadingButton>
                               &nbsp;

                               &nbsp;
                               <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={UpdateAllSkusTask}                                    
                               >
                                   Update All Asin Skus Task
                               </LoadingButton>
                               &nbsp;

                               &nbsp;
                               <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={UpdateProfilesV3}                                    
                               >
                                   Update User Profiles
                               </LoadingButton>
                               &nbsp; 

                               <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={UpdateStoreProductsV3}                                    
                               >
                                   Update Store Products Task
                               </LoadingButton>                               
                               
                               
                           </CardBody>
                       </Card>
                   </Col>
               </Row>
               
           </Container>
            <Container fluid>
           
           &nbsp;                               

           &nbsp;
           <Row className="mb-3">
                    <Col md="12" lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                            <CardTitle>SPAPIData Single StoreActions </CardTitle>
                                <Row>
                                    <Col md="6" className="mb-2"> 
                                    <DropdownList
                                    data={props.sellerPartnerList}
                                    dataKey='id'                    
                                    textField='Name'                   
                                    placeholder="Select"
                                    value={sellerPartnerId}
                                    onChange={(value) => {
                                        setSellerPartnerId(value);
                                     }}
                                />   
                                    </Col>        
                                </Row>                                   
                                <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getLastDaySPAPIData}                                    
                                >
                                    Get data for last day
                                </LoadingButton>

                                &nbsp;   

                                <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getHistSPAPIData}                                    
                                >
                                    Get 15 Days data
                                </LoadingButton>
                                &nbsp;                               
                               <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getLastYearSPAPIData}                                    
                                >
                                   Get One Year data Task 
                                </LoadingButton>    

                                &nbsp;                               
                               <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getCatalogueItemDetailsSPAPIData}                                    
                                >
                                   Get Catalogue Item Details
                                </LoadingButton>   
                                &nbsp;
                                <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getMerchantListingsForSellerPartnerID}                                    
                                >
                                    Get Merchant Listings   
                                </LoadingButton>   

                                <br />
                                <br />
                                <h5>All Store Bulk Jobs</h5>                                
                                <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={getLastDaySPAPIDataTask}                                    
                               >
                                  Daily all stores Task
                               </LoadingButton>
                               &nbsp;
                               <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={get15DaysHistSPAPIDataTask}                                    
                                >
                                   Get 15 days data Task 
                                </LoadingButton>     
                                                  
                                &nbsp;
                               <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={get30DaysHistSPAPIDataTask}                                    
                                >
                                   Get 30 days data Task  
                                </LoadingButton>                             
                                &nbsp;
                               <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getAllCatalogueItemDetailsSPAPIData}                                    
                                >
                                   Get Catalogue Item Details Task  
                                </LoadingButton>
                                &nbsp;
                                <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getAllMerchantListings}                                    
                                >
                                   Get Merchant Listings Task  
                                </LoadingButton>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                           
                       
                            
           </Container>

            <Container fluid>
           
            &nbsp;                               

            &nbsp;
                <Row className="mb-3">
                    <Col md="12" lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                            <CardTitle>Advertising Job Actions </CardTitle>
                               
                                &nbsp;
                               
                                <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={RequestDailyAdvV3Reports}                                    
                                >
                                  Request Daily Reports
                                </LoadingButton>

                                &nbsp;

                                { <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={SaveSummaryReportsV3}                                    
                                >
                                   Save Daily Reports 
                                </LoadingButton>}

                                &nbsp;  
                               

                                <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={FetchPendingReportsV3}                                    
                                >
                                Fetch Pending Reports 
                                </LoadingButton>

                                &nbsp; 
                                <br /> 
                                &nbsp;
                               
                               <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={Request15DaysHistDataAdvReportsTask}                                    
                               >
                                15 days Hist Report Task
                               </LoadingButton>

                               &nbsp;                             
                               
                               <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={Request30DaysHistDataAdvReportsTask}                                    
                               >
                                30 days Hist Report Task
                               </LoadingButton>

                               &nbsp;

                               { <LoadingButton
                                   className="mt-4"
                                   loading={loading}
                                   color="brand-blue-dark"
                                   onClick={SaveAllAdvReportsTask}                                    
                               >
                                  Save Reports Task
                               </LoadingButton>}

                              

                                <br /> 
                                
                                &nbsp;   
                                <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={FetchErrorReportsV3}                                    
                                >
                                   Fetch Error Reports 
                                </LoadingButton>

                                &nbsp; 

                                <LoadingButton
                                    className="mt-4"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={RetryErrorReportV3}                                    
                                >
                                    Retry Error Reports
                                </LoadingButton>
                                &nbsp;
                              
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>                        

                        <Card>
                            <AllJobsListDataTable data={productStatus} />
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>                        

                        <Card>
                            <AdvReportsStatus data={productStatus} />
                        </Card>
                    </Col>
                </Row>
            </Container>

            

        </>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    sellerPartnerList:state.profile.sellerPartnerList,   
})


export default connect(mapStateToProps)(AdvertisingJobPage);