import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormFeedback,
  FormGroup,
  Input,
  CardTitle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { DropdownList } from "react-widgets";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import CreateNewTicketService from "../../../../../../Service/DashboardServices/TroubleTicketServices/CreateNewTicketService";
import ProductsAlertTicketCreateService from "../../../../../../Service/DashboardServices/TroubleTicketServices/ProductsAlertTicketCreateService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from "../../../../../../Utils/Constants";

function ProductsAlertTicketCreate(props) {
  const [selectedProblemArea, setSelectedProblemArea] = useState([]);
  const [subRequestTypes, setSubRequestTypes] = useState([]);
  const [selectedProblemPathWay, setSelectedProblemPathWay] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [checkpathway, setCheckpathway] = useState([]);
  const [checked, setChecked] = useState(false);
  const [asinArray, setAsinArray] = useState([]);
  const [isInternalTicket, setIsInternalTicket] = useState(
    props.accessLevel == 0 ? true : false
  );

  const [formData, setFormData] = useState({
    ticketTitle: "",
    mainRequestType: props.requestTypeID ? props.requestTypeID : "",
    subRequestType: props.requestSubTypeID ? props.requestSubTypeID : "",
    product: props.asinForTicketList ? props.asinForTicketList : "",
    integerId: props.storeOverview.integerID,
    storeName: props.storeOverview.name,
    sku: "",

    selectedProblemArea: "",
    selectedProblemPathWay: "",
    subRequestTypes: "",

    ticketHistoryComment: "",
    problemAreaId: props.problemAreaId ? props.problemAreaId : "",
    problemAreaTitle: "",
    problemAreaPathwayId: props.problemAreaPathwayId
      ? props.problemAreaPathwayId
      : "",
    problemAreaPathwayTitle: "",
    isInternalTicket: isInternalTicket,
  });
  const [loading, setLoading] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    const newFormData = new FormData();
    console.log("Form Data Submitted:", formData);
    newFormData.append("ticketTitle", formData.ticketTitle);
    newFormData.append("mainRequestType", 2);
    newFormData.append("problemAreaId", formData.selectedProblemArea);
    newFormData.append("problemAreaPathwayId", formData.selectedProblemPathWay);
    newFormData.append("subRequestType", formData.subRequestTypes);
    newFormData.append("asin", formData.product);
    newFormData.append("sku", formData.sku);
    newFormData.append("integerId", formData.integerId);
    newFormData.append("storeName", formData.storeName);
    newFormData.append(
      "isInternalTicket",
      isInternalTicket ? isInternalTicket : false
    );
    newFormData.append("ticketHistoryComment", formData.ticketHistoryComment);

    for (let i = 0; i < selectedFiles.length; i++) {
      newFormData.append("ticketAttachmentFile", selectedFiles[i]);
    }
    console.log(newFormData);
    CreateNewTicketService(props.token, newFormData).then((response) => {
      if (response) {
        props.handleCreateAndViewTicketClose(response);
        console.log("response");
        console.log(response);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    ProductsAlertTicketCreateService(props.token).then((response) => {
      if (response) {
        setSelectedProblemArea(response.problemAreaList);
        setSubRequestTypes(response.subRequestTypes);
        setSelectedProblemPathWay(response.problemAreaPathwayList);
      }
    });
  }, [props.token]);

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };

  const handleProblemType = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (e) => {
    const newFiles = [...selectedFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setSelectedFiles(newFiles);
  };

  const handleSelectSubRequestType = (name, id) => {
    const filteredSubRequestTypes = props.subRequestTypes.filter(
      (subType) => subType.problemAreaPathwayId == id
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mainRequestType") {
      handleSelectSubRequestType(name, value);
      setFormData({ ...formData, [name]: value });
    } else if (name === "isInternalTicket") {
      setFormData({ ...formData, [name]: e.target.checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleQuillChange = (value) => {
    setFormData({ ...formData, ticketHistoryComment: value });
    console.log(formData)
  }
  function handleChange(e) {
    setChecked(e.target.checked);
    const value = e.target.value;
    if (e.target.checked === false) {
      const updatedArray = [...asinArray];
      updatedArray.pop(value);
      setAsinArray(updatedArray);
      setFormData({ ...formData, ["product"]: updatedArray });
      console.log(asinArray);
    } else if (e.target.checked === true) {
      const updatedArray = [...asinArray];
      updatedArray.push(value);
      setAsinArray(updatedArray);
      setFormData({ ...formData, ["product"]: updatedArray });
    }
  }
  return (
    <Container fluid className="">
      <Row className="m-0 justify-content-center">
        <Col md="12" className="mbg-3 font-size-lg p-0">
          <Card className="main-card">
            <CardBody>
              <Fragment>
                <div>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6} className="mt-2">
                        <div>
                          <label htmlFor="selectedProblemArea">
                            <b>{"1)"}Problem Area :</b>
                          </label>
                          <select
                            id="selectedProblemArea"
                            name="selectedProblemArea"
                            value={formData.selectedProblemArea}
                            onChange={handleInputChange}
                            className="form-control"
                          >
                            <option value="">--Select Problem Area --</option>
                            {selectedProblemArea
                              ? selectedProblemArea.map((e) => (
                                <option key={e.id} value={e.id}>
                                  {e.problemArea}
                                </option>
                              ))
                              : ""}
                          </select>
                        </div>
                      </Col>
                      <Col md={6} className="mt-2">
                        <div>
                          <label htmlFor="selectedProblemPathWay">
                            <b>{"2)"}Problem Pathway :</b>
                          </label>
                          <select
                            id="selectedProblemPathWay"
                            name="selectedProblemPathWay"
                            value={formData.selectedProblemPathWay}
                            onChange={handleInputChange}
                            className="form-control"
                          >
                            <option value="">
                              --Select Problem pathway --
                            </option>
                            {selectedProblemPathWay
                              ? selectedProblemPathWay.map((type) =>
                                formData.selectedProblemArea ==
                                  type.problemAreaId ? (
                                  <option key={type.id} value={type.id}>
                                    {type.problemAreaPathway}
                                  </option>
                                ) : (
                                  ""
                                )
                              )
                              : ""}
                          </select>
                        </div>
                      </Col>
                      <Col md={6} className="mt-2">
                        <div>
                          <label htmlFor="subRequestTypes">
                            <b>{"3)"}Problem Type :</b>
                          </label>
                          <select
                            id="subRequestTypes"
                            name="subRequestTypes"
                            value={formData.subRequestTypes}
                            onChange={handleProblemType}
                            className="form-control"
                          >
                            <option value="">--Select Problem Type --</option>
                            {subRequestTypes
                              ? subRequestTypes.map((type) =>
                                formData.selectedProblemPathWay ==
                                  type.problemAreaPathwayId ? (
                                  <option key={type.id} value={type.id}>
                                    {type.requestSubTypes}
                                  </option>
                                ) : (
                                  ""
                                )
                              )
                              : ""}
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        {props.accessLevel == 0 ?
                          <Label className="text-center mt-4">
                            <Input
                              type="checkbox"
                              name="isInternalTicket"
                              checked={isInternalTicket}
                              onChange={(e) => {
                                setIsInternalTicket(!isInternalTicket);
                                handleInputChange(e);
                              }}
                            />
                            <span>
                              <b> Is it an internal ticket?</b>
                            </span>
                          </Label> : ''}
                      </Col>

                      <Col md={6} className="mt-4">
                        {/* <Input
                              id="ticketAttachment"
                              name="ticketAttachment"
                              className="mt-3"
                              type="file"
                              onChange={handleFileChange}
                            /> */}
                        <label
                          htmlFor="fileInput"
                          className="custom-file-input-label text-center"
                        >
                          <i className="fas fa-upload"></i>
                          <br /> Choose File
                        </label>
                        <input
                          type="file"
                          id="fileInput"
                          name="ticketAttachment"
                          className="custom-file-input"
                          onChange={handleFileChange}
                        />
                      </Col>
                      <Col md={6} className="mt-4">
                        <span style={{ fontSize: "13px" }}>
                          Attached file List
                        </span>
                        <ul style={{ color: "green", fontSize: "13px" }}>
                          {selectedFiles
                            ? selectedFiles.map((file, index) => (
                              <li key={index}>
                                {file.name}
                                <button
                                  className="btn"
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  <FontAwesomeIcon
                                    color="red"
                                    size="15px"
                                    icon={faWindowClose}
                                  />
                                </button>
                              </li>
                            ))
                            : ""}
                        </ul>
                      </Col>
                      <Col md={12}>
                        <Col md={12} className="" style={{ marginTop: 40 }}>
                          <div>
                            <ReactQuill
                              value={formData.ticketHistoryComment}
                              onChange={handleQuillChange}
                              theme={'snow'}
                              className="quil-style"
                              modules={Editor.modules}
                              formats={Editor.formats}
                              bounds={'.quill-prod'}

                              placeholder='Please describe your problem' />
                            {/* <textarea
                              id="ticketHistoryComment"
                              name="ticketHistoryComment"
                              value={formData.ticketHistoryComment}
                              className="form-control"
                              onChange={handleInputChange}
                              // rows={8}
                              style={{ height: 195 }}
                              placeholder="Please describe your problem"
                            ></textarea> */}

                          </div>
                        </Col>

                        {props.accessLevel === 0 ? (
                          <Col md={12} className="ml-5"></Col>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col
                        md={12}
                        className={
                          subRequestTypes
                            ? subRequestTypes.map((type) =>
                              type.id == formData.subRequestType
                                ? type.asinDisplay
                                  ? ""
                                  : " d-none"
                                : ""
                            )
                            : ""
                        }
                      >
                        <Col md={12}>
                          <label htmlFor="">
                            <b>
                              Product(s)
                              <span
                                className={
                                  subRequestTypes
                                    ? subRequestTypes.map((type) =>
                                      type.id == formData.subRequestType
                                        ? type.asinMandatory
                                          ? ""
                                          : " d-none"
                                        : ""
                                    )
                                    : ""
                                }
                                style={{ color: "red" }}
                              >
                                *
                              </span>{" "}
                              :
                            </b>
                          </label>
                          <div className="">
                            {props.storeProducts.length > 0 ? (
                              props.storeProducts.map((product) =>
                                props.asinForTicketList == product.asin ? (
                                  <>
                                    <Row className="m-0 p-0">
                                      <Col md="1" className="mt-2 p-0">
                                        <div className="">
                                          <input
                                            className="mt-4 ml-4  "
                                            value={product.asin}
                                            type="checkbox"
                                            name={product.asin}
                                            onChange={handleChange}
                                            checked={
                                              props.asinForTicketList ==
                                                product.asin
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        md="11"
                                        className="border row-background-color"
                                      >
                                        <Row className="justify-content-center ">
                                          <Col md="2" className="p-2">
                                            <div>
                                              <img
                                                className="ml-0"
                                                width={"100%"}
                                                src={product.itemImage}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="10" className="p-2">
                                            <div>
                                              <h6>
                                                <b>{product.productTitle}</b>
                                              </h6>
                                              <div className="">
                                                <Row>
                                                  <Col md={5}>
                                                    <span>
                                                      <b>ASIN : </b>{" "}
                                                      {product.asin}
                                                    </span>
                                                  </Col>
                                                  <Col md={7}>
                                                    {/* <div>
                                                <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                              </div> */}
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </>
                                ) : props.asinForTicketList == "" ? (
                                  <>
                                    <Row className="m-0 p-0">
                                      <Col md="1" className="mt-2 p-0">
                                        <div className="">
                                          <input
                                            className="mt-4 ml-4  "
                                            value={product.asin}
                                            type="checkbox"
                                            name={product.asin}
                                            onChange={handleChange}
                                          // checked={props.asinForTicketList == product.asin ? true : false}
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        md="11"
                                        className="border row-background-color"
                                      >
                                        <Row className="justify-content-center ">
                                          <Col md="2" className="p-2">
                                            <div>
                                              <img
                                                className="ml-0"
                                                width={"100%"}
                                                src={product.itemImage}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="10" className="p-2">
                                            <div>
                                              <h6>
                                                <b>{product.productTitle}</b>
                                              </h6>
                                              <div className="">
                                                <Row>
                                                  <Col md={5}>
                                                    <span>
                                                      <b>ASIN : </b>{" "}
                                                      {product.asin}
                                                    </span>
                                                  </Col>
                                                  <Col md={7}>
                                                    {/* <div>
                                                <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                              </div> */}
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  ""
                                )
                              )
                            ) : (
                              <p className="text-center">
                                {" "}
                                No product available.
                              </p>
                            )}
                          </div>
                        </Col>
                      </Col>
                    </Row>

                    <p className=" mt-3 text-center">

                      <Button
                        className="btn_class"
                        disabled={!formData.ticketHistoryComment ? true : (loading ? true : false)}
                      >
                        {loading ? 'Submitting...' : 'Submit'}
                      </Button>
                    </p>
                  </form>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(ProductsAlertTicketCreate);
