import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';

function saveAdvSPAPISummariesService(token, data) {
    
    return axios.post(`${CURRENT_API_URL}/sellerPartner/saveAdvSPAPISummaries`,data, {
        
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Saving advertising SPAPI reports summaries."))      
            .then(res => {
                notify.NotifySuccess("All advertising SPAPI reports summaries have been saved in DB.");
                return true;
            }).catch(() => {
                notify.NotifyError("An error occurred while saving advertising SPAPI reports summaries.");
                return false;
            });
}
export {
    saveAdvSPAPISummariesService,
}